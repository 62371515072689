
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: { VFormBuilder },
})
export default class PaymentForm extends mixins(StackedForm) {
  public $refs!: {
    baseForm: InstanceType<typeof VFormBuilder> & { form: any };
    paymentForm: InstanceType<typeof VFormBuilder> & { form: any };
    preorderForm: InstanceType<typeof VFormBuilder> & { form: any };
    deliveryForm: InstanceType<typeof VFormBuilder> & { form: any };
    ccForm: InstanceType<typeof VFormBuilder> & { form: any };
    ppForm: InstanceType<typeof VFormBuilder> & { form: any };
    sepaForm: InstanceType<typeof VFormBuilder> & { form: any };
    sbForm: InstanceType<typeof VFormBuilder> & { form: any };
    apForm: InstanceType<typeof VFormBuilder> & { form: any };
    gpForm: InstanceType<typeof VFormBuilder> & { form: any };
    walleeCredentials: InstanceType<typeof VFormBuilder> & { form: any };
    walleePaymentConfs: InstanceType<typeof VFormBuilder> & { form: any };
    worldlineCredentials: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get initValues() {
    return this.initialValues as Venue;
  }

  get generalItems() {
    return [
      {
        name: 'currency',
        type: InputType.Select,
        default: 'EUR',
        label: 'venue.form.currency',
        rules: 'required',
        items: this.currencies,
        change: this.onCurrencyChange,
      },
      {
        name: 'webAppUrl',
        type: InputType.Text,
        label: 'venue.form.webAppUrl',
      },
      {
        name: 'supplier',
        type: InputType.Text,
        label: 'venue.form.supplier',
      },
      { name: 'provisionRate', type: InputType.Text, label: 'venue.form.provisionRate', default: '0' },
      {
        name: 'provisionFixedRate',
        type: InputType.Text,
        default: '0',
        label: 'venue.form.provisionFixedRate',
      },
      {
        name: 'vrAccessToken',
        type: InputType.Text,
        default: '',
        label: 'venue.form.vrAccessToken',
      },
      {
        name: 'vrPaymentEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.vrPaymentEnabled',
      },
      {
        name: 'ppV2Enabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.ppV2Enabled',
      },
      {
        name: 'ppFullIntegrationEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.ppFullIntegrationEnabled',
      },
      {
        name: 'walleeEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.walleeEnabled',
      },
      {
        name: 'tacEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.tacEnabled',
      },
      {
        name: 'comoLoyaltyEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.comoLoyaltyEnabled',
      },
    ];
  }

  get paymentItems() {
    return [
      { name: 'ccvApikey', type: InputType.Text, label: 'venue.form.ccvApikey' },
      { name: 'comoApiKey', type: InputType.Text, label: 'venue.form.comoApiKey' },
      { name: 'paymentEnabled', type: InputType.Checkbox, label: 'venue.form.paymentEnabled' },
      { name: 'cashEnabled', type: InputType.Checkbox, label: 'venue.form.cashEnabled' },
      { name: 'posEnabled', type: InputType.Checkbox, label: 'venue.form.posEnabled' },
      { name: 'sepaEnabled', type: InputType.Checkbox, label: 'venue.form.sepaEnabled' },
      { name: 'ccEnabled', type: InputType.Checkbox, label: 'venue.form.ccEnabled' },
      { name: 'sbEnabled', type: InputType.Checkbox, label: 'venue.form.sbEnabled' },
      { name: 'ppEnabled', type: InputType.Checkbox, label: 'venue.form.ppEnabled' },
      { name: 'gpEnabled', type: InputType.Checkbox, label: 'venue.form.gpEnabled' },
      { name: 'apEnabled', type: InputType.Checkbox, label: 'venue.form.apEnabled' },
      { name: 'rekaEnabled', type: InputType.Checkbox, label: 'venue.form.rekaEnabled' },
      { name: 'twintEnabled', type: InputType.Checkbox, label: 'venue.form.twintEnabled' },
      { name: 'pfEnabled', type: InputType.Checkbox, label: 'venue.form.pfEnabled' },
      { name: 'pfcEnabled', type: InputType.Checkbox, label: 'venue.form.pfcEnabled' },
      { name: 'bcEnabled', type: InputType.Checkbox, label: 'venue.form.bcEnabled' },
      { name: 'lchEnabled', type: InputType.Checkbox, label: 'venue.form.lchEnabled' },
      { name: 'wcEnabled', type: InputType.Checkbox, label: 'venue.form.wcEnabled' },
      { name: 'alipayEnabled', type: InputType.Checkbox, label: 'venue.form.alipayEnabled' },
      { name: 'cwlEnabled', type: InputType.Checkbox, label: 'venue.form.cwlEnabled' },
      { name: 'ccvEnabled', type: InputType.Checkbox, label: 'venue.form.ccvEnabled' },
      { name: 'zeroPaymentEnabled', type: InputType.Checkbox, default: false, label: 'venue.form.zeroPaymentEnabled' },
      { name: 'worldlineEnabled', type: InputType.Checkbox, label: 'venue.form.worldlineEnabled' },
      { name: 'woltPaymentEnabled', type: InputType.Checkbox, default: false, label: 'venue.form.woltPaymentEnabled' },
      {
        name: 'uberEatsPaymentEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.uberEatsPaymentEnabled',
      },
      { name: 'jetPaymentEnabled', type: InputType.Checkbox, default: false, label: 'venue.form.jetPaymentEnabled' },
      {
        name: 'ctCreditCardPaymentEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.ctCreditCardPaymentEnabled',
      },
      {
        name: 'bankTransferPaymentEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.bankTransferPaymentEnabled',
      },
    ];
  }

  get preorderPaymentItems() {
    return [
      { name: 'ccPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.ccEnabled' },
      { name: 'ppPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.ppEnabled' },
      { name: 'gpPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.gpEnabled' },
      { name: 'apPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.apEnabled' },
      { name: 'cashPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.cashEnabled' },
      { name: 'sepaPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.sepaEnabled' },
      { name: 'rekaPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.rekaEnabled' },
      { name: 'twintPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.twintEnabled' },
      { name: 'pfPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.pfEnabled' },
      { name: 'pfcPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.pfcEnabled' },
      { name: 'bcPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.bcEnabled' },
      { name: 'lchPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.lchEnabled' },
      { name: 'wcPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.wcEnabled' },
      { name: 'alipayPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.alipayEnabled' },
      { name: 'cwlPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.cwlEnabled' },
      { name: 'ccvPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.ccvEnabled' },
      { name: 'zeroPaymentPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.zeroPaymentEnabled' },
      { name: 'worldlinePreorderEnabled', type: InputType.Checkbox, label: 'venue.form.worldlineEnabled' },
      { name: 'woltPaymentPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.woltPaymentEnabled' },
      { name: 'uberEatsPaymentPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.uberEatsPaymentEnabled' },
      { name: 'jetPaymentPreorderEnabled', type: InputType.Checkbox, label: 'venue.form.jetPaymentEnabled' },
      {
        name: 'ctCreditCardPaymentPreorderEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.ctCreditCardPaymentEnabled',
      },
      {
        name: 'bankTransferPaymentPreorderEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.bankTransferPaymentEnabled',
      },
    ];
  }

  get deliveryPaymentItems() {
    return [
      { name: 'ccDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.ccEnabled' },
      { name: 'ppDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.ppEnabled' },
      { name: 'apDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.apEnabled' },
      { name: 'cashDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.cashEnabled' },
      { name: 'sepaDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.sepaEnabled' },
      { name: 'rekaDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.rekaEnabled' },
      { name: 'twintDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.twintEnabled' },
      { name: 'pfDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.pfEnabled' },
      { name: 'pfcDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.pfcEnabled' },
      { name: 'bcDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.bcEnabled' },
      { name: 'lchDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.lchEnabled' },
      { name: 'wcDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.wcEnabled' },
      { name: 'alipayDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.alipayEnabled' },
      { name: 'cwlDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.cwlEnabled' },
      { name: 'ccvDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.ccvEnabled' },
      { name: 'zeroPaymentDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.zeroPaymentEnabled' },
      { name: 'worldlineDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.worldlineEnabled' },
      { name: 'woltPaymentDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.woltPaymentEnabled' },
      { name: 'uberEatsPaymentDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.uberEatsPaymentEnabled' },
      { name: 'jetPaymentDeliveryEnabled', type: InputType.Checkbox, label: 'venue.form.jetPaymentEnabled' },
      {
        name: 'ctCreditCardPaymentDeliveryEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.ctCreditCardPaymentEnabled',
      },
      {
        name: 'bankTransferPaymentDeliveryEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.bankTransferPaymentEnabled',
      },
    ];
  }

  get ccCredentialItems() {
    return [
      { name: 'ccMerchantId', type: InputType.Text, label: 'venue.form.merchantId' },
      { name: 'ccSecretKey', type: InputType.Text, label: 'venue.form.secretKey' },
    ];
  }

  get apCredentialItems() {
    return [{ name: 'apMerchantId', type: InputType.Text, label: 'venue.form.merchantId' }];
  }

  get gpCredentialItems() {
    return [{ name: 'gpMerchantId', type: InputType.Text, label: 'venue.form.merchantId' }];
  }

  get ppCredentialItems() {
    return [
      { name: 'ppMerchantId', type: InputType.Text, label: 'venue.form.merchantId' },
      { name: 'ppSecretKey', type: InputType.Text, label: 'venue.form.secretKey' },
      { name: 'ppEmail', type: InputType.Text, label: 'venue.form.ppEmail', rules: 'email' },
    ];
  }

  get sbCredentialItems() {
    return [{ name: 'sbMerchantId', type: InputType.Text, label: 'venue.form.merchantId' }];
  }

  get sepaCredentialItems() {
    return [
      { name: 'sepaMerchantId', type: InputType.Text, label: 'venue.form.merchantId' },
      { name: 'sepaSecretKey', type: InputType.Text, label: 'venue.form.secretKey' },
      { name: 'sepaMerchantName', type: InputType.Text, label: 'venue.form.merchantName' },
      { name: 'sepaCreditorId', type: InputType.Text, label: 'venue.form.creditorId' },
    ];
  }

  get currencies() {
    return [
      { value: 'EUR', currencySymbol: '€', text: this.$t('currency.euro') },
      { value: 'CHF', currencySymbol: 'CHF', text: this.$t('currency.chf') },
      { value: 'ISK', currencySymbol: 'ISK', text: this.$t('currency.isk') },
    ];
  }

  get walleeCredentials() {
    return [
      { name: 'userId', type: InputType.Text, label: 'venue.form.userId' },
      { name: 'spaceId', type: InputType.Text, label: 'venue.form.spaceId' },
      { name: 'userKey', type: InputType.Text, label: 'venue.form.userKey' },
    ];
  }

  get walleePaymentConfs() {
    return [
      { name: 'reka', type: InputType.Text, label: 'venue.form.reka' },
      { name: 'twint', type: InputType.Text, label: 'venue.form.twint' },
      { name: 'boncard', type: InputType.Text, label: 'venue.form.boncard' },
      { name: 'luncheck', type: InputType.Text, label: 'venue.form.luncheck' },
      { name: 'postfinance', type: InputType.Text, label: 'venue.form.postfinance' },
      { name: 'postfinance_card', type: InputType.Text, label: 'venue.form.postfinanceCard' },
      { name: 'wechat', type: InputType.Text, label: 'venue.form.wechat' },
      { name: 'alipay', type: InputType.Text, label: 'venue.form.alipay' },
      { name: 'worldline', type: InputType.Text, label: 'venue.form.worldline' },
    ];
  }

  get worldlineCredentials() {
    return [
      { name: 'apiKey', type: InputType.Text, label: 'venue.form.apiKey' },
      { name: 'apiSecret', type: InputType.Text, label: 'venue.form.apiSecret' },
      { name: 'pspId', type: InputType.Text, label: 'venue.form.pspId' },
    ];
  }

  get ppdAuthorized() {
    return (
      this.initValues &&
      this.initValues?.ppEmailConfirmed &&
      !!this.initValues?.ppMerchantId &&
      !!this.initValues?.ppSecretKey &&
      !!this.initValues?.ppEmail
    );
  }

  get ppReceivable() {
    return (
      this.initValues &&
      this.initValues?.ppPaymentsReceivable &&
      !!this.initValues?.ppMerchantId &&
      !!this.initValues?.ppSecretKey &&
      !!this.initValues?.ppEmail
    );
  }
  public onCurrencyChange(val: string, form: { currencySymbol?: string }) {
    let symbol: string = '';
    for (const cur of this.currencies) {
      if (cur.value === val) {
        symbol = cur.currencySymbol;
        form.currencySymbol = symbol;
        return;
      }
    }
  }

  public getData() {
    const data: Partial<Venue> = {
      ...this.$refs.baseForm.form,
      ...this.$refs.deliveryForm.form,
      ...this.$refs.preorderForm.form,
      ...this.$refs.paymentForm.form,
      ...this.$refs.ccForm.form,
      ...this.$refs.ppForm.form,
      ...this.$refs.sepaForm.form,
      ...this.$refs.sbForm.form,
      ...this.$refs.apForm.form,
      ...this.$refs.gpForm.form,
      walleeCredentials: {
        ...this.$refs.walleeCredentials.form,
      },
      walleePaymentConfs: {
        ...this.$refs.walleePaymentConfs.form,
      },
      worldlineCredentials: {
        ...this.$refs.worldlineCredentials.form,
      },
    };

    delete data.ppEmailConfirmed;
    delete data.ppPaymentsReceivable;

    return data;
  }
}
